import React from 'react'
import { NavLink } from 'react-router-dom'
import './Lots.scss'

const SubMenu = () => {
  const currentMenu = window.location.pathname;
  const menu = [
    { title: 'Winning', path: '/lots/winning' },
    { title: 'Open items', path: '/lots/open-items' },
    { title: 'Not Paid', path: '/lots/not-paid' },
    { title: 'History', path: '/lots/history' },
  ]

  return (
    <div className='lots-sub-menu'>
      {menu.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className={currentMenu === item.title ? 'active' : ''}
        >
          {item.title}
        </NavLink>
      ))}
    </div>
  )
}

export default SubMenu;
