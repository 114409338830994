import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import SubMenu from './SubMenu'
import { invoicesColumns } from './TableColumns'
import DataTable from '../../../Common/DataTable/DataTable'
import { formatCurrency, formatDate } from '../../../tools/format'

const dataMapping = (lots) => lots.map(lot => {
  const filteredLot = {}

  const columnNames = invoicesColumns.map(header => header.propName)
  columnNames.forEach(header => filteredLot[header] = lot[header] ?? 'N/A')

  const link = lot['detailedUrl'];

  filteredLot['car'] =
    <div>
      <div>{lot['year']} {lot['make']} {lot['model']}</div>
      <div>Lot: <a target='_blank' rel="noreferrer" href={link}>#{lot['lotNumber']}</a></div>
      <div>VIN: {lot['vin']}</div>
    </div>;

  const downloadLink = `/uploads/${lot['lotNumber']}.pdf`;

  filteredLot['totalAmount'] =
    <div>
      <div>Total Amount: <a  target='_blank' rel="noreferrer" href={downloadLink}>{formatCurrency(lot['totalAmount'])}</a></div>
      <div>High Bid Price: {formatCurrency(lot['highBidPrice'])}</div>
      {lot['memberPaidDate'] && <div>Paid Date: {formatDate(lot['memberPaidDate'])}</div>}
    </div>;

  // filteredLot['invoiceDate'] = formatDate(lot['invoiceDate']);

  const userLink = lot['userName'] ? <Link to={`/users/${lot['userName']}`}>{lot['userName']}</Link> : null;
  const buyerLink = lot['userName'] ? <Link to='/accounts'>{lot['buyerNumber']}</Link> : null;

  filteredLot['userName'] =
    <div>
      <div>User: {userLink ?? 'N/A'}</div>
      <div>Buyer: {buyerLink}</div>
    </div>;

  return filteredLot
})

const Invoices = ({ apiEndpoint }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_BASE_URL + apiEndpoint)
      .then(response => {
        let invoices = dataMapping(response.data.data)
        setData(invoices)
      })
      .catch(err => {
        console.log(err)
      })
  }, [apiEndpoint])

  return (
    <>
      <SubMenu />
      <div className="invoices-table">
        <DataTable
          data={data}
          showSort={true}
          header={invoicesColumns}
          actions={false}
          dataSetter={setData}
          hiddenCol={['id']}
          imageCol={['imageUrl']}
        />
      </div>
    </>
  )
}

export default Invoices
