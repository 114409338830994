const columns = [
  {
    id: 110,
    name: 'Image',
    sortable: false,
    flex: 1,
    propName: 'imageUrl',
    isActive: false,
    isImage: true
  },
  {
    id: 111,
    name: 'Car',
    sortable: true,
    flex: 1,
    propName: 'car',
    isActive: false,
  },
  {
    id: 112,
    name: 'Winner',
    sortable: true,
    flex: 1,
    propName: 'userName',
    isActive: false,
  },
  {
    id: 113,
    name: 'Location',
    sortable: true,
    flex: 1,
    propName: 'location',
    isActive: false,
  },
  {
    id: 114,
    name: 'Auction',
    sortable: true,
    flex: 1,
    propName: 'auction',
    isActive: false,
  },

]

export const lotsColumns = [
  ...columns,
  {
    id: 116,
    name: 'Sale Date',
    sortable: true,
    flex: 1,
    propName: 'saleDate',
    isActive: false,
  },
  {
    id: 117,
    name: 'Bid',
    sortable: true,
    flex: 1,
    propName: 'bid',
    isActive: false,
  },
]

export const invoicesColumns = [
  ...columns,
  {
    id: 116,
    name: 'Invoice Date',
    sortable: true,
    flex: 1,
    propName: 'invoiceDate',
    isActive: false,
  },
  {
    id: 117,
    name: 'Price',
    sortable: true,
    flex: 1,
    propName: 'totalAmount',
    isActive: false,
  },
]
