import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import SubMenu from './SubMenu'
import { lotsColumns } from './TableColumns'
import DataTable from '../../../Common/DataTable/DataTable'
import { formatCurrency, formatDate } from '../../../tools/format'

const dataMapping = (lots) => lots.map(lot => {
  const filteredLot = {}

  const columnNames = lotsColumns.map(header => header.propName)
  columnNames.forEach(header => filteredLot[header] = lot[header] ?? 'N/A')

  const link = lot['detailedUrl'];

  filteredLot['car'] =
    <div>
      <div>{lot['year']} {lot['make']} {lot['model']}</div>
      <div>Lot: <a target='_blank' rel="noreferrer" href={link}>#{lot['lotNumber']}</a></div>
      <div>VIN: {lot['vin']}</div>
    </div>;

  filteredLot['bid'] = `Current Bid: ${formatCurrency(lot['currentBid'])}`;
  filteredLot['bid'] += lot['myMaxBid'] ? `\nMy Max Bid: ${formatCurrency(lot['myMaxBid'])}` : '';
  filteredLot['bid'] += lot['counterBidStatus'] ? (`\nStatus: ${lot['counterBidStatus'] !== 'DEFAULT' ? lot['counterBidStatus'] : lot['memberBidStatus']}`) : '';

  filteredLot['saleDate'] = formatDate(lot['saleDate']);
  filteredLot['userName'] = `User: ${lot['userName'] ?? 'N/A'}\nBuyer: ${lot['buyerNumber']}`;

  const userLink = lot['userName'] ? <Link to={`/users/${lot['userName']}`}>{lot['userName']}</Link> : null;

  filteredLot['userName'] =
    <div>
      <div>User: {userLink ?? 'N/A'}</div>
      <div>Buyer: {lot['buyerNumber']}</div>
    </div>;


  return filteredLot
})

const Lots = ({ apiEndpoint }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_BASE_URL + apiEndpoint)
      .then(response => {
        let lots = dataMapping(response.data.data)
        setData(lots)
      })
      .catch(err => {
        console.log(err)
      })
  }, [apiEndpoint])

  return (
    <>
      <SubMenu />
      <div className="lots-table">
        <DataTable
          data={data}
          showSort={true}
          header={lotsColumns}
          actions={false}
          dataSetter={setData}
          hiddenCol={['id']}
          imageCol={['imageUrl']}
        />
      </div>
    </>
  )
}

export default Lots
